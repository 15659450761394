<template>
  <v-dialog
    :value="mostrar"
    width="50%"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar color="grey lighten-3" flat dense>
        <v-toolbar-title> Adicionar veículo</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn text small @click="$emit('fechar')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-7">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="placa_veiculo">
                <v-text-field
                  v-model="placa"
                  ref="placa_veiculo"
                  v-mask="'XXXXXXX'"
                  label="Placa"
                  dense
                  outlined
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="renavam">
                <v-text-field
                  v-model="renavam"
                  ref="renavam"
                  v-mask="''"
                  label="Renavam"
                  dense
                  outlined
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="cor_id">
                <v-autocomplete
                  item-text="nome_portugues"
                  autocomplete="nope"
                  hide-details="auto"
                  item-value="id"
                  v-model="cor"
                  ref="cor_id"
                  outlined
                  dense
                  :loading="carregandoCores"
                  :error-messages="errors"
                  :label="labelCampoCor"
                  :value="valueCor"
                  :items="cores"
                  :filter="filtrarCores"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="estado_id">
                <v-autocomplete
                  item-text="descricao"
                  autocomplete="nope"
                  hide-details="auto"
                  v-model="estado"
                  ref="estado_id"
                  item-value="id"
                  outlined
                  dense
                  :loading="carregandoEstados"
                  :label="labelCampoEstado"
                  :error-messages="errors"
                  :value="valueEstado"
                  :items="estados"
                  :filter="filtrarPorDescricao"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="cidade_id">
                <v-autocomplete
                  item-text="descricao"
                  v-model="municipio"
                  autocomplete="nope"
                  hide-details="auto"
                  ref="cidade_id"
                  item-value="id"
                  outlined
                  dense
                  :loading="carregandoCidades"
                  :label="labelCampoCidade"
                  :error-messages="errors"
                  :value="valueMunicipio"
                  :items="municipios"
                  :filter="filtrarPorDescricao"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="veiculo_marca_id">
                <v-autocomplete
                  ref="veiculo_marca_id"
                  item-text="descricao"
                  autocomplete="nope"
                  hide-details="auto"
                  item-value="id"
                  v-model="marca"
                  outlined
                  dense
                  :loading="carregandoMarcas"
                  :label="labelCampoMarca"
                  :error-messages="errors"
                  :value="valueMarca"
                  :items="marcas"
                  :filter="filtrarPorDescricao"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="veiculo_modelo_id">
                <v-autocomplete
                  ref="veiculo_modelo_id"
                  item-text="descricao"
                  autocomplete="nope"
                  hide-details="auto"
                  v-model="modelo"
                  item-value="id"
                  outlined
                  dense
                  :loading="carregandoModelos"
                  :label="labelCampoModelo"
                  :error-messages="errors"
                  :value="valueModelo"
                  :items="modelos"
                  :filter="filtrarPorDescricao"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="ano_fabricacao">
                <v-text-field
                  label="Ano de fabricacao"
                  v-model="anoFabricacao"
                  ref="ano_fabricacao"
                  hide-details="auto"
                  v-mask="''"
                  outlined
                  dense
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="ano_modelo">
                <v-text-field
                  label="Ano do modelo"
                  v-model="anoModelo"
                  hide-details="auto"
                  ref="ano_modelo"
                  v-mask="''"
                  outlined
                  dense
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="chassi">
                <v-text-field
                  hide-details="auto"
                  v-model="chassi"
                  label="Chassi"
                  ref="chassi"
                  v-mask="''"
                  outlined
                  dense
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="tipo_veiculo_id">
                <v-autocomplete
                  ref="tipo_veiculo_id"
                  item-text="descricao"
                  autocomplete="nope"
                  hide-details="auto"
                  :value="valueTipo"
                  item-value="id"
                  v-model="tipo"
                  outlined
                  dense
                  :loading="carregandoTiposVeiculo"
                  :error-messages="errors"
                  :label="labelCampoTipo"
                  :items="tipos"
                  :filter="filtrarPorDescricao"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="motor">
                <v-text-field
                  hide-details="auto"
                  v-model="motor"
                  label="Motor"
                  v-mask="''"
                  ref="motor"
                  outlined
                  dense
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="portas">
                <v-text-field
                  hide-details="auto"
                  v-model="portas"
                  label="Portas"
                  type="number"
                  ref="portas"
                  v-mask="''"
                  outlined
                  min="0"
                  max="4"
                  dense
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="pessoa_relacionada_cpf">
                <input-autocomplete-pessoas
                  ref="pessoa_relacionada_cpf"
                  label="Pessoa relacionada"
                  item-text="descricao"
                  autocomplete="nope"
                  hide-details="auto"
                  item-value="id"
                  outlined
                  dense
                  :error-messages="errors"
                  :value="valuePessoa"
                  :items="pessoas"
                  :pessoa-adicionada="pessoa"
                  @change="selecaoPessoaRelacionada"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col class="pb-0">
              <p class="body-1 mb-0 font-weight-black">Dados do Proprietário</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="proprietario_cpf">
                <v-text-field
                  v-model="proprietario.cpf"
                  v-mask="'###.###.###-##'"
                  hide-details="auto"
                  label="CPF"
                  outlined
                  dense
                  :error-messages="errors"
                  @focusout="buscarProprietarioPorCPF"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="proprietario_telefone">
                <v-text-field
                  v-model="proprietario.telefone"
                  v-mask="'(##) # ####-####'"
                  hide-details="auto"
                  label="Telefone"
                  outlined
                  dense
                  :error-messages="errors"
                  :loading="carregandoDadosProprietario"
                  :disabled="desabilitarCamposProprietario.telefone"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="proprietario_nome">
                <v-text-field
                  v-model="proprietario.nome"
                  hide-details="auto"
                  v-mask="''"
                  label="Nome"
                  outlined
                  dense
                  :error-messages="errors"
                  :loading="carregandoDadosProprietario"
                  :disabled="desabilitarCamposProprietario.nome"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col class="pb-0">
              <p class="body-1 mb-0 font-weight-black">Dados do Possuidor</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="possuidor_cpf">
                <v-text-field
                  v-mask="'###.###.###-##'"
                  v-model="possuidor.cpf"
                  hide-details="auto"
                  label="CPF"
                  outlined
                  dense
                  :error-messages="errors"
                  @focusout="buscarPossuidorPorCPF"
                />
              </validation-provider>
            </v-col>

            <v-col>
              <validation-provider v-slot="{ errors }" vid="possuidor_telefone">
                <v-text-field
                  v-model="possuidor.telefone"
                  v-mask="'(##) # ####-####'"
                  hide-details="auto"
                  label="Telefone"
                  outlined
                  dense
                  :error-messages="errors"
                  :loading="carregandoDadosPossuidor"
                  :disabled="desabilitarCamposPossuidor.telefone"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="possuidor_nome">
                <v-text-field
                  v-model="possuidor.nome"
                  hide-details="auto"
                  label="Nome"
                  v-mask="''"
                  outlined
                  dense
                  :error-messages="errors"
                  :loading="carregandoDadosPossuidor"
                  :disabled="desabilitarCamposPossuidor.nome"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col class="pb-0">
              <p class="body-1 mb-0 font-weight-black">Alerta</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="pontos_captura">
                <v-select
                  v-model="pontosCapturaSelecionados"
                  label="Pontos de Captura"
                  item-text="descricao"
                  ref="pontos_captura"
                  hide-details="auto"
                  item-value="id"
                  multiple
                  outlined
                  dense
                  :loading="carregandoPontosCaptura"
                  :error-messages="errors"
                  :items="pontosCaptura"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>
                      <span>{{ item.descricao }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ pontosCapturaSelecionados.length - 1 }} outros)
                    </span>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="dias_semana">
                <v-select
                  v-model="diasDaSemanaSelecionados"
                  label="Dias da Semana"
                  item-text="descricao"
                  hide-details="auto"
                  ref="dias_semana"
                  item-value="id"
                  multiple
                  outlined
                  dense
                  :items="$store.state.layout.diasDaSemana"
                  :error-messages="errors"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>
                      <span>{{ item.descricao }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ diasDaSemanaSelecionados.length - 1 }} outros)
                    </span>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" md="4">
              <v-menu
                v-model="menuDataValidade"
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="validade_alerta"
                  >
                    <v-text-field
                      v-model="dataValidadeFormatada"
                      append-icon="mdi-calendar"
                      ref="validade_alerta"
                      v-mask="'##/##/####'"
                      label="Data Validade"
                      hide-details="auto"
                      autocomplete="off"
                      outlined
                      dense
                      :error-messages="errors"
                      @blur="dataValidade = formatarDataApi($event.target.value)"
                      @click:append="menuDataValidade = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="dataValidade"
                  no-title
                  class="ma-0"
                  @input="menuDataValidade = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <btn-salvar outlined :carregando="carregandoSalvar" @click="salvar"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiPontosCaptura from "@/api/pontos-captura";
import apiOcorrencias from "@/api/ocorrencias";
import formatarData from "@/utils/formatar-data-input";
import marcas from '@/api/marcas'
import modelos from '@/api/modelos'
import veiculos from '@/api/veiculos'
import tipos from '@/api/tipos-veiculos'
import cores from '@/api/cores'
import cidades from '@/api/cidades'
import estados from '@/api/estados'
import apiPessoas from '@/api/pessoas'
import apiTelefones from '@/api/telefones'

export default {
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    limparAposSalvar: {
      type: Boolean,
      default: true,
    },
    ocorrenciaId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      placa: null,
      renavam: null,
      anoFabricacao: null,
      anoModelo: null,
      chassi: null,
      motor: null,
      portas: null,
      proprietario: {
        id: 0,
        cpf: null,
        telefone: null,
        nome: null
      },
      possuidor: {
        id: 0,
        cpf: null,
        telefone: null,
        nome: null
      },
      modelos: [],
      modelo: null,
      tipos: [],
      tipo: null,
      cor: null,
      cores: [],
      pessoa: {},
      pessoas: [],
      municipio: null,
      municipios: [],
      marcas: [],
      marca: null,
      estado: null,
      estados: [],
      valueTipo: 0,
      valueModelo: 0,
      valueCor: 0,
      valueEstado: 0,
      valueMunicipio: 0,
      valuePessoa: 0,
      valueMarca: 0,
      pontosCaptura: [],
      status: 1,
      diasDaSemanaSelecionados: [1, 2, 3, 4, 5, 6, 7],
      pontosCapturaSelecionados: [],
      dataValidade: null,
      menuDataValidade: false,
      dataValidadeFormatada: null,

      labels: {
        cor: {
          carregado: "Cor",
          carregando: "Cor (Buscando...)",
          porCarregar: "Cor",
          erro: "Cor (Erro ao buscar)"
        },
        estado: {
          carregado: "Estado da placa",
          carregando: "Estado da placa (Buscando...)",
          porCarregar: "Estado da placa",
          erro: "Estado (Erro ao buscar)"
        },
        cidade: {
          carregado: "Município da placa",
          carregando: "Município da placa (Buscando...)",
          porCarregar: "Município da placa (Selec. o Estado)",
          erro: "Município (Erro ao buscar)"
        },
        marca: {
          carregado: "Marca",
          carregando: "Marca (Buscando...)",
          porCarregar: "Marca",
          erro: "Marca (Erro ao buscar)"
        },
        modelo: {
          carregado: "Modelo",
          carregando: "Modelo (Buscando...)",
          porCarregar: "Modelo (Selec. a Marca)",
          erro: "Modelo (Erro ao buscar)"
        },
        tipoVeiculo: {
          carregado: "Tipo",
          carregando: "Tipo (Buscando...)",
          porCarregar: "Tipo",
          erro: "Tipo (Erro ao buscar)"
        },
      },

      labelCampoCor: "",
      labelCampoEstado: "",
      labelCampoCidade: "",
      labelCampoMarca: "",
      labelCampoModelo: "",
      labelCampoTipo: "",

      carregandoSalvar: false,
      carregandoCores: false,
      carregandoEstados: false,
      carregandoCidades: false,
      carregandoMarcas: false,
      carregandoModelos: false,
      carregandoTiposVeiculo: false,
      carregandoPontosCaptura: false,
      carregandoDadosProprietario: false,
      carregandoDadosPossuidor: false,

      desabilitarCamposProprietario: {
        nome: false,
        telefone: false,
      },
      desabilitarCamposPossuidor: {
        nome: false,
        telefone: false,
      },
    };
  },
  watch: {
    dataValidade: {
      immediate: true,
      handler(data) {
        this.dataValidadeFormatada = formatarData.ptBr(data);
      },
    },
    marca(value) {
      this.carregarModelos()
    },
    estado(value) {
      this.carregarCidades()
    },
    placa(value) {
      this.placa = this.placa == null || this.placa.length <= 0 ? null : this.placa.toUpperCase();
    }
  },
  components: {
    InputAutocompletePessoas: () => import('@/components/input/InputAutocompletePessoas'),
  },
  mounted() {
    this.labelCampoCor = this.labels.cor.porCarregar
    this.labelCampoEstado = this.labels.estado.porCarregar
    this.labelCampoCidade = this.labels.cidade.porCarregar
    this.labelCampoMarca = this.labels.marca.porCarregar
    this.labelCampoModelo = this.labels.modelo.porCarregar
    this.labelCampoTipo = this.labels.tipoVeiculo.porCarregar

    this.buscarPontosCaptura()
    this.carregarMarcas()
    this.carregarTipos()
    this.carregarCores()
    this.carregarEstados()
  },
  methods: {
    async buscarProprietarioPorCPF(event) {
      this.carregandoDadosProprietario = true;
      this.desabilitarCamposProprietario.telefone = true;
      this.desabilitarCamposProprietario.nome = true;

      this.proprietario.id = 0;
      this.proprietario.nome = null;
      this.proprietario.telefone = null;

      let pessoaProprietario = await this.buscarPessoaPorCPF(event.target.value);
      this.desabilitarCamposProprietario.telefone = false; //- Deixa o campo de fone habilitado, caso o usuario queira cadastrar um novo

      if (pessoaProprietario === null || pessoaProprietario === false) {
        this.carregandoDadosProprietario = false;
        this.desabilitarCamposProprietario.nome = false;
        return;
      }

      this.proprietario.id = pessoaProprietario.id;
      this.proprietario.nome = pessoaProprietario.nome;
      this.desabilitarCamposProprietario.nome = true;

      if (pessoaProprietario.telefones.data && pessoaProprietario.telefones.data.length > 0) {
        let lastIndex = pessoaProprietario.telefones.data.length - 1;
        this.proprietario.telefone = pessoaProprietario.telefones.data[lastIndex].telefone.numero_telefone
      }

      this.carregandoDadosProprietario = false;
    },

    async buscarPossuidorPorCPF(event) {
      this.carregandoDadosPossuidor = true;
      this.desabilitarCamposPossuidor.telefone = true;
      this.desabilitarCamposPossuidor.nome = true;

      this.possuidor.id = 0;
      this.possuidor.nome = null;
      this.possuidor.telefone = null;

      let pessoaPossuidor = await this.buscarPessoaPorCPF(event.target.value);
      this.desabilitarCamposPossuidor.telefone = false; //- Deixa o campo de fone habilitado, caso o usuario queira cadastrar um novo

      if (pessoaPossuidor === null || pessoaPossuidor === false) {
        this.carregandoDadosPossuidor = false;
        this.desabilitarCamposPossuidor.nome = false;
        return;
      }

      this.possuidor.id = pessoaPossuidor.id;
      this.possuidor.nome = pessoaPossuidor.nome;
      this.desabilitarCamposPossuidor.nome = true;

      if (pessoaPossuidor.telefones.data && pessoaPossuidor.telefones.data.length > 0) {
        let lastIndex = pessoaPossuidor.telefones.data.length - 1;
        this.possuidor.telefone = pessoaPossuidor.telefones.data[lastIndex].telefone.numero_telefone
      }

      this.carregandoDadosPossuidor = false;
    },

    async buscarPessoaPorCPF(cpf) {
      cpf = cpf.replace(/\D/g,"");
      if (cpf.length !== 11)
        return null;

      try {
        const listaPessoas = await apiPessoas.listar(`cpf=${cpf}`);
        if (!listaPessoas.data.pessoas || listaPessoas.data.pessoas.length <= 0)
          return false;

        let pessoaCPF = listaPessoas.data.pessoas[0];
        let telefones = await apiPessoas.listarTelefones(pessoaCPF.id);

        pessoaCPF.telefones = telefones;
        return pessoaCPF;
      } catch (e) {
        return false;
      }
    },

    async salvar() {
      try {
        this.carregandoSalvar = true;

        let errosCadastroPessoas = {};
        if (this.proprietario.id <= 0 && this.proprietario.cpf && this.proprietario.cpf.length) {
          try {
            let pessoaCadastrada = await this.registrarProprietarioOuPossuidor(this.proprietario);
            this.proprietario.id = pessoaCadastrada.id;
          } catch (e) {
            if (e.response.data.erro.erros_validacao.cpf && e.response.data.erro.erros_validacao.cpf.length) {
              errosCadastroPessoas.proprietario_cpf = e.response.data.erro.erros_validacao.cpf;
            }
            if (e.response.data.erro.erros_validacao.nome && e.response.data.erro.erros_validacao.nome.length) {
              errosCadastroPessoas.proprietario_nome = e.response.data.erro.erros_validacao.nome;
            }
            if (e.response.data.erro.erros_validacao.numero_telefone && e.response.data.erro.erros_validacao.numero_telefone.length) {
              errosCadastroPessoas.proprietario_telefone = e.response.data.erro.erros_validacao.numero_telefone;
            }
          }
        }
        if (this.possuidor.id <= 0 && this.possuidor.cpf && this.possuidor.cpf.length) {
          try {
            let pessoaCadastrada = await this.registrarProprietarioOuPossuidor(this.possuidor);
            this.possuidor.id = pessoaCadastrada.id;
          } catch (e) {
            if (e.response.data.erro.erros_validacao.cpf && e.response.data.erro.erros_validacao.cpf.length) {
              errosCadastroPessoas.possuidor_cpf = e.response.data.erro.erros_validacao.cpf;
            }
            if (e.response.data.erro.erros_validacao.nome && e.response.data.erro.erros_validacao.nome.length) {
              errosCadastroPessoas.possuidor_nome = e.response.data.erro.erros_validacao.nome;
            }
            if (e.response.data.erro.erros_validacao.numero_telefone && e.response.data.erro.erros_validacao.numero_telefone.length) {
              errosCadastroPessoas.possuidor_telefone = e.response.data.erro.erros_validacao.numero_telefone;
            }
          }
        }

        if (Object.keys(errosCadastroPessoas).length) {
          this.$refs.observer.setErrors(errosCadastroPessoas);
          return;
        }

        this.registrarTelefoneParaPessoa(this.proprietario);
        this.registrarTelefoneParaPessoa(this.possuidor);

        const dados = {
          cor_id: this.cor,
          cidade_id: this.municipio,
          pessoa_relacionada_id: this.pessoa.id || null,
          pessoa_relacionada_cpf: this.pessoa.cpf || this.pessoa.cnpj || null,
          pessoa_proprietario_id: this.proprietario.id > 0 ? this.proprietario.id : null,
          pessoa_possuidor_id: this.possuidor.id > 0 ? this.possuidor.id : null,
          renavam: this.renavam,
          chassi: this.chassi,
          motor: this.motor,
          ano_fabricacao: this.anoFabricacao,
          ano_modelo: this.anoModelo,
          portas: this.portas,
          placa_veiculo: this.placa,
          veiculo_modelo_id: this.modelo,
          tipo_veiculo_id: this.tipo,
        };

        let veiculo = await veiculos.salvar(dados);

        await apiOcorrencias.adicionarVeiculo({
          ocorrencia_id: this.ocorrenciaId,
          placa_veiculo: this.placa,
          veiculo_id: veiculo.id,
          dias_semana: this.diasDaSemanaSelecionados.join(","),
          pontos_captura: this.pontosCapturaSelecionados.join(","),
          status_alerta: this.status,
          validade_alerta: this.dataValidade
        });

        if (this.limparAposSalvar) {
          this.placa = null;
          this.renavam = null;
          this.cor = null;
          this.estado = null;
          this.municipio = null;
          this.marca = null;
          this.modelo = null;
          this.anoFabricacao = null;
          this.anoModelo = null;
          this.chassi = null;
          this.tipo = null;
          this.motor = null;
          this.portas = null;
          this.pessoa = {};

          this.proprietario.id = 0;
          this.proprietario.cpf = null;
          this.proprietario.telefone = null;
          this.proprietario.nome = null;
          this.desabilitarCamposProprietario.telefone = false;
          this.desabilitarCamposProprietario.nome = false;

          this.possuidor.id = 0;
          this.possuidor.cpf = null;
          this.possuidor.telefone = null;
          this.possuidor.nome = null;
          this.desabilitarCamposPossuidor.telefone = false;
          this.desabilitarCamposPossuidor.nome = false;

          //this.pontosCapturaSelecionados = [];
          //this.diasDaSemanaSelecionados = [];
          //this.dataValidade = null;

          this.pessoas = [];

          this.valueTipo = 0;
          this.valueModelo = 0;
          this.valueCor = 0;
          this.valueEstado = 0;
          this.valueMunicipio = 0;
          this.valuePessoa = 0;
        }

        this.$emit("salvou");
      } catch (e) {
        if (e.response) {
          if (e.response.status && e.response.status === 422) {
            let erros = this.$erroApi.validacao(e);
            this.$refs.observer.setErrors(erros);

            if (erros === false) return;

            let menorUID = 0;
            let campoMenorUID = "";
            for (let key in erros) {
              if (!(key in this.$refs)) {
                continue;
              }

              if (campoMenorUID === "" || this.$refs[key]._uid < menorUID) {
                menorUID = this.$refs[key]._uid;
                campoMenorUID = key;
              }
            }

            if (menorUID > 0) {
              this.$refs[campoMenorUID].focus();
            }
            return;
          }

          this.$snackbar.mostrar({cor: "error", mensagem: this.$erroApi._(e)});
        } else {
          console.log(e);
          this.$snackbar.mostrar({cor: "error", mensagem: 'Ocorreu um erro. Entre em contato com o suporte.'});
        }
      } finally {
        this.carregandoSalvar = false;
      }
    },

    async registrarProprietarioOuPossuidor(dados) {
      let dadosPessoa = {
        nome: dados.nome,
        cpf: dados.cpf.replace(/\D/g, ""),
        tipo_pessoa: 1 //- 1=Pessoa Fisica
      };

      let pessoaCadastrada = await apiPessoas.inserir(dadosPessoa);
      return pessoaCadastrada.data;
    },

    async registrarTelefoneParaPessoa(pessoa) {
      if (!pessoa.telefone || !pessoa.telefone.length) {
        return;
      }

      let telefoneCadastrado = await apiTelefones.inserir({
        numero_telefone: pessoa.telefone
      });

      apiPessoas.inserirTelefone({
        pessoa_id: pessoa.id,
        telefone_id: telefoneCadastrado.data.id,
        status: 1
      });
    },

    async buscarPontosCaptura() {
      try {
        this.carregandoPontosCaptura = true;
        const resposta = await apiPontosCaptura.listar({por_pagina: 1000});
        this.pontosCaptura = resposta.data.data;
        this.pontosCaptura.map((v) => {
          this.pontosCapturaSelecionados.push(v.id);
        });
      } catch (e) {
        this.$snackbar.mostrar({cor: "error", mensagem: this.$erroApi._(e)});
      } finally {
        this.carregandoPontosCaptura = false;
      }
    },

    formatarDataApi(data) {
      return formatarData.api(data);
    },

    async carregarModelos() {
      let marcaID = Number(this.marca);
      if (marcaID <= 0) {
        this.modelos = [];
        this.labelCampoModelo = this.labels.modelo.porCarregar;
        return;
      }

      try {
        this.carregandoModelos = true
        this.labelCampoModelo = this.labels.modelo.carregando;

        const resposta = await modelos.listar({
          veiculo_marca_id: this.marca,
          pagina: 1,
          por_pagina: 1000,
        });

        this.modelos = resposta.data.data
        this.labelCampoModelo = this.labels.modelo.carregado;
      } catch (e) {
        this.labelCampoModelo = this.labels.modelo.erro;
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregandoModelos = false
      }
    },

    async carregarMarcas() {
      try {
        this.carregandoMarcas = true
        this.labelCampoMarca = this.labels.marca.carregando

        const resposta = await marcas.listar({
          pagina: 1,
          por_pagina: 1000,
        })
        this.marcas = resposta.data.data
        this.labelCampoMarca = this.labels.marca.carregado
      } catch (e) {
        this.labelCampoMarca = this.labels.marca.erro;
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregandoMarcas = false
      }
    },

    async carregarTipos() {
      try {
        this.carregandoTiposVeiculo = true
        this.labelCampoTipo = this.labels.tipoVeiculo.carregando

        const resposta = await tipos.listar({
          pagina: 1,
          por_pagina: 1000,
        })
        this.tipos = resposta.data.tipos_veiculo
        this.labelCampoTipo = this.labels.tipoVeiculo.carregado
      } catch (e) {
        this.labelCampoTipo = this.labels.tipoVeiculo.erro
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregandoTiposVeiculo = false
      }
    },

    async carregarCores() {
      try {
        this.carregandoCores = true
        this.labelCampoCor = this.labels.cor.carregando

        const resposta = await cores.buscarCores({
          pagina: 1,
          por_pagina: 1000,
        })
        this.cores = resposta.data.data
        this.labelCampoCor = this.labels.cor.carregado
      } catch (e) {
        this.labelCampoCor = this.labels.cor.erro
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregandoCores = false
      }
    },

    async carregarEstados() {
      try {
        this.carregandoEstados = true
        this.labelCampoEstado = this.labels.estado.carregando

        const resposta = await estados.listar()

        this.estados = resposta.data
        this.labelCampoEstado = this.labels.estado.carregado
      } catch (e) {
        this.labelCampoEstado = this.labels.estado.erro
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregandoEstados = false
      }
    },

    async carregarCidades() {
      try {
        this.carregandoCidades = true
        this.labelCampoCidade = this.labels.cidade.carregando

        const resposta = await cidades.listar({
          estado_id: this.estado,
          pagina: 1,
          por_pagina: 100000,
        })
        this.municipios = resposta.data.cidades
        this.labelCampoCidade = this.labels.cidade.carregado
      } catch (e) {
        this.labelCampoCidade = this.labels.cidade.erro
        this.$snackbar.mostrar({cor: 'error', mensagem: this.$erroApi._(e)})
      } finally {
        this.carregandoCidades = false
      }
    },
    selecaoPessoaRelacionada(pessoaRelacionada) {
      if (this.pessoa.id !== pessoaRelacionada.id) {
        this.pessoa = pessoaRelacionada;
      }
    },
    removerAcentos(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    filtrarPorDescricao(item, queryText, itemText) {
      let textoItem = this.removerAcentos(item.descricao).toLocaleLowerCase();
      let textoPesquisa = this.removerAcentos(queryText).toLocaleLowerCase();
      return textoItem.indexOf(textoPesquisa) > -1;
    },
    filtrarCores(item, queryText, itemText) {
      let textoItem = this.removerAcentos(item.nome_portugues).toLocaleLowerCase();
      let textoPesquisa = this.removerAcentos(queryText).toLocaleLowerCase();
      return textoItem.indexOf(textoPesquisa) > -1;
    },
  },
};
</script>

<style lang="css">
.dialog-inserir-veiculo {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dialog-inserir-veiculo {
    width: 50% !important;
  }
}
</style>
