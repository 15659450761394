import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`veiculos?${parametros}`)
  },

  async salvar (dados) {
    return await axios.post('veiculos', dados)
  },

}
