import axios from '@/plugins/axios'

export default {

  async listar (query) {
    return await axios.get(`telefones?${query}`)
  },

  async buscar (id) {
    return await axios.get(`telefones/${id}`)
  },

  async inserir (dados) {
    return await axios.post('telefones', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`telefones/${id}`, dados)
  },

}
